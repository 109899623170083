var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.containerClasses},[_c('div',{staticClass:"two-image-hero-banner__container",on:{"mouseleave":function () { return _vm.mouseLeave(); }}},[_c('div',{staticClass:"two-image-hero-banner__item two-image-hero-banner__item--left",on:{"mouseover":function () { return _vm.mouseOver('left'); }}},[_c('button',{staticClass:"picture-btn",on:{"click":function () { return _vm.onClick(_vm.item.left_content); }}},[_c('LazyPicture',{attrs:{"item":_vm.item.left_content,"preserve-ratio":"true"}})],1),_vm._v(" "),_c('div',{staticClass:"two-image-hero-banner__content"},[(_vm.item.left_content.headline)?_c('h3',{staticClass:"headline",domProps:{"textContent":_vm._s(_vm.item.left_content.headline)}}):_vm._e(),_vm._v(" "),(_vm.item.left_content.title)?_c('h2',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.item.left_content.title)}}):_vm._e(),_vm._v(" "),(
            _vm.isInternalLink(_vm.item.left_content.cta_link) &&
              _vm.item.left_content.cta_label
          )?_c('router-link',{class:_vm.linkClasses,attrs:{"to":_vm.localizedUrl + _vm.item.left_content.cta_link},domProps:{"textContent":_vm._s(_vm.item.left_content.cta_label)}}):_vm._e(),_vm._v(" "),(
            !_vm.isInternalLink(_vm.item.left_content.cta_link) &&
              _vm.item.left_content.cta_label
          )?_c('a',{class:_vm.linkClasses,attrs:{"href":_vm.item.left_content.cta_link},domProps:{"textContent":_vm._s(_vm.item.left_content.cta_label)}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"two-image-hero-banner__item two-image-hero-banner__item--right",on:{"mouseover":function () { return _vm.mouseOver('right'); }}},[_c('button',{staticClass:"picture-btn",on:{"click":function () { return _vm.onClick(_vm.item.right_content); }}},[_c('LazyPicture',{attrs:{"item":_vm.item.right_content,"preserve-ratio":"true"}})],1),_vm._v(" "),_c('div',{staticClass:"two-image-hero-banner__content"},[(_vm.item.right_content.headline)?_c('h3',{staticClass:"headline",domProps:{"textContent":_vm._s(_vm.item.right_content.headline)}}):_vm._e(),_vm._v(" "),(_vm.item.right_content.title)?_c('h2',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.item.right_content.title)}}):_vm._e(),_vm._v(" "),(
            _vm.isInternalLink(_vm.item.right_content.cta_link) &&
              _vm.item.right_content.cta_label
          )?_c('router-link',{class:_vm.linkClasses,attrs:{"to":_vm.localizedUrl + _vm.item.right_content.cta_link},domProps:{"textContent":_vm._s(_vm.item.right_content.cta_label)}}):_vm._e(),_vm._v(" "),(
            !_vm.isInternalLink(_vm.item.right_content.cta_link) &&
              _vm.item.right_content.cta_label
          )?_c('a',{class:_vm.linkClasses,attrs:{"href":_vm.item.right_content.cta_link},domProps:{"textContent":_vm._s(_vm.item.right_content.cta_label)}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
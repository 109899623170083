import { render, staticRenderFns } from "./TwoImagesHeroBanner.vue?vue&type=template&id=a186d2f0&scoped=true&"
import script from "./TwoImagesHeroBanner.vue?vue&type=script&lang=js&"
export * from "./TwoImagesHeroBanner.vue?vue&type=script&lang=js&"
import style0 from "./TwoImagesHeroBanner.vue?vue&type=style&index=0&id=a186d2f0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a186d2f0",
  null
  
)

export default component.exports
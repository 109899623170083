<template>
  <section :class="containerClasses">
    <div
      class="two-image-hero-banner__container"
      @mouseleave="() => mouseLeave()"
    >
      <div
        class="two-image-hero-banner__item two-image-hero-banner__item--left"
        @mouseover="() => mouseOver('left')"
      >
        <button class="picture-btn" @click="() => onClick(item.left_content)">
          <LazyPicture
            :item="item.left_content"
            preserve-ratio="true"
          />
        </button>

        <div class="two-image-hero-banner__content">
          <h3
            class="headline"
            v-if="item.left_content.headline"
            v-text="item.left_content.headline"
          ></h3>
          <h2
            class="title"
            v-if="item.left_content.title"
            v-html="item.left_content.title"
          ></h2>
          <router-link
            :to="localizedUrl + item.left_content.cta_link"
            :class="linkClasses"
            v-text="item.left_content.cta_label"
            v-if="
              isInternalLink(item.left_content.cta_link) &&
                item.left_content.cta_label
            "
          ></router-link>
          <a
            :href="item.left_content.cta_link"
            :class="linkClasses"
            v-text="item.left_content.cta_label"
            v-if="
              !isInternalLink(item.left_content.cta_link) &&
                item.left_content.cta_label
            "
          ></a>
        </div>
      </div>
      <div
        class="two-image-hero-banner__item two-image-hero-banner__item--right"
        @mouseover="() => mouseOver('right')"
      >
        <button class="picture-btn" @click="() => onClick(item.right_content)">
          <LazyPicture
            :item="item.right_content"
            preserve-ratio="true"
          />
        </button>

        <div class="two-image-hero-banner__content">
          <h3
            class="headline"
            v-if="item.right_content.headline"
            v-text="item.right_content.headline"
          ></h3>
          <h2
            class="title"
            v-if="item.right_content.title"
            v-html="item.right_content.title"
          ></h2>
          <router-link
            :to="localizedUrl + item.right_content.cta_link"
            :class="linkClasses"
            v-text="item.right_content.cta_label"
            v-if="
              isInternalLink(item.right_content.cta_link) &&
                item.right_content.cta_label
            "
          ></router-link>
          <a
            :href="item.right_content.cta_link"
            :class="linkClasses"
            v-text="item.right_content.cta_label"
            v-if="
              !isInternalLink(item.right_content.cta_link) &&
                item.right_content.cta_label
            "
          ></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import LazyPicture from '../utils/LazyPicture.vue'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '@vue-storefront/core/helpers'

export default {
  name: 'TwoImagesHeroBanner',
  props: ['item', 'pagePosition', 'sibling-components'],
  mixins: [ReadyHook],
  created() {},
  mounted() {},
  data() {
    return {
      activeContent: 'none'
    }
  },
  computed: {
    localizedUrl() {
      return currentStoreView().url || ''
    },
    containerClasses() {
      return {
        'two-image-hero-banner--white': this.item.text_color === 'white',
        'two-image-hero-banner--mobile-white':
          this.item.text_color_mobile === 'white',
        'two-image-hero-banner': true,
        'two-image-hero-banner--active-left': this.activeContent === 'left',
        'two-image-hero-banner--active-right': this.activeContent === 'right'
      }
    },
    linkClasses() {
      return {
        'link-light': true,
        '-white': this.item.text_color === 'white',
        '-white-mobile': this.item.text_color_mobile === 'white'
      }
    },
    isMobile() {
      return isServer ? true : this.$store.state['zenith-ui'].menu.mobile
    }
  },
  methods: {
    isInternalLink(link) {
      return link && !link.includes('http')
    },
    onClick(content) {
      if (this.isInternalLink(content.cta_link)) {
        this.$router.push(this.localizedUrl + content.cta_link)
      } else {
        window.location.href = content.cta_link
      }
    },
    mouseOver(side) {
      this.activeContent = side
    },
    mouseLeave(side) {
      this.activeContent = 'none'
    }
  },
  components: { LazyPicture }
}
</script>

<style scoped lang="scss">
@import '~theme/css/core';

.two-image-hero-banner {
  color: var(--zenith-black);

  &--white {
    color: var(--zenith-white);

    &:not(.two-image-hero-banner--mobile-white) {
      @include bp(max md) {
        color: var(--zenith-black);
      }
    }
  }

  &--mobile-white {
    @include bp(max md) {
      color: var(--zenith-white);
    }
  }

  &--active-left {
    .two-image-hero-banner__item--left {
      width: 70%;

      @include bp(max md) {
        width: 100%;
      }
    }
  }

  &--active-right {
    .two-image-hero-banner__item--right {
      width: 70%;

      @include bp(max md) {
        width: 100%;
      }
    }
  }

  &__container {
    display: flex;

    @include bp(max md) {
      display: flex;
      flex-flow: column;
    }
  }

  &__item {
    width: 50%;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    transition: all 1s ease;
    height: $hero-available-height;

    @include bp(max md) {
      width: 100%;
      height: calc(50vh - 4rem);
    }

    /deep/ img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--left {
      .two-image-hero-banner__content {
        left: 3rem;
      }
    }

    &--right {
      h2, h3 {
        text-align: right;
      }

      .two-image-hero-banner__content {
        right: 3rem;
        display: flex;
        flex-flow: column;
        align-items: flex-end;
      }
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    min-height: 120px;
    justify-content: space-between;

    h2, h3 {
      max-width: 80%;
    }

    @include bp(max md) {
      min-height: unset;
      width: 100%;
    }
  }
}

.headline {
  display: block;
  font-size: em(16);
  font-weight: 400;
  line-height: 1;
  letter-spacing: em(0.2, 16);
  margin-bottom: 1.3rem;
}

.title {
  font-size: em(38);
  font-weight: 400;
  line-height: 1;
  letter-spacing: em(0.2, 38);
  margin-bottom: 3rem;
}

.picture-btn {
  border: none;
  padding: 0;
  outline: none;
  height: 100%;
  max-width: 100%;

  > div {
    height: 100%;
    width: 100%;
  }
}
</style>
